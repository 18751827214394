var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gaming-container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mb-5 py-5"},[_c('b-button',{staticClass:"btn font-weight-bolder text-uppercase btn-primary py-4 px-6 ml-4",attrs:{"variant":"secondary"},on:{"click":_vm.goToDash}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa fa-chevron-left"}),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t("generic.buttons.back"))+" ")])])])],1),(_vm.srcSVG)?_c('simple-svg',{attrs:{"id":"simple-svg","src":_vm.srcSVG,"width":"100%","height":"80%"},on:{"load":function($event){return _vm.svgLoaded()}}}):_vm._e(),_c('b-modal',{attrs:{"id":"question-modal","hide-footer":"","hide-header":"","size":"xl","scrollable":"","centered":"","content-class":"p-10"}},[(_vm.selectedQuestion)?_c('div',[_c('h2',{staticClass:"text-center pb-10",domProps:{"innerHTML":_vm._s(_vm.selectedQuestion.title)}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between row"},_vm._l((_vm.selectedQuestion.choices),function(answer,answerIndex){return _c('div',{key:answerIndex,staticClass:"d-flex flex-column col-xl-4 answer ove"},[_c('b-button',{staticClass:"font-weight-bolder btn-shadow-hover p-8 font-size-lg",class:_vm.preset !== false && _vm.preset.index === answerIndex
                  ? ''
                  : answerIndex === _vm.answeredIndex
                  ? _vm.selectedQuestion.valuesRelation[answer.value] === 1
                    ? 'text-white'
                    : 'text-white'
                  : _vm.selectedQuestion.valuesRelation[answer.value] === 1
                  ? _vm.isAnswered
                    ? 'light-primary'
                    : 'light'
                  : 'light',staticStyle:{"border-radius":"60px"},attrs:{"disabled":_vm.isAnswered,"variant":_vm.preset !== false && _vm.preset.index === answerIndex
                  ? 'secondary'
                  : answerIndex === _vm.answeredIndex
                  ? _vm.selectedQuestion.valuesRelation[answer.value] === 1
                    ? 'primary'
                    : 'danger'
                  : _vm.selectedQuestion.valuesRelation[answer.value] === 1
                  ? _vm.isAnswered
                    ? 'light-primary'
                    : 'light'
                  : 'light'},domProps:{"innerHTML":_vm._s(answer.text)},on:{"click":function($event){return _vm.doAnswer(answer.value, _vm.selectedQuestion, answerIndex)}}})],1)}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAnswered),expression:"isAnswered"}],staticClass:"card card-custom mb-2 mt-8 p-4 cardround",staticStyle:{"border-radius":"100px !important"},attrs:{"id":"answer"}},[_c('div',{staticClass:"text-dark-75 font-weight-boldest pl-15"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"text-align":"left","font-size":"1.2em","padding-right":"4.5em"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.message)}})])]),_c('div',{staticClass:"mt-4 text-center"},[_c('b-btn',{attrs:{"variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" CONTINUAR ")])],1)])]):_vm._e()]),(_vm.markFinished)?_c('div',{attrs:{"id":"escaped"}},[_c('h1',[_vm._v("Escape!")]),_c('div',[_c('b-button',{staticClass:"btn font-weight-bolder text-uppercase btn-primary py-4 px-6",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.finishCyberGaming(_vm.session.surveyEntityId)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.$t("generic.buttons.finish"))+" ")])])])],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }