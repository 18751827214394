<template>
  <div class="gaming-container">
    <div class="container">
      <div class="row mb-5 py-5">
        <b-button
          variant="secondary"
          @click="goToDash"
          class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6 ml-4"
        >
          <div class="d-flex align-items-center">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
      </div>
      <simple-svg
        id="simple-svg"
        :src="srcSVG"
        v-if="srcSVG"
        width="100%"
        height="80%"
        @load="svgLoaded()"
      />
      <b-modal
        id="question-modal"
        hide-footer
        hide-header
        size="xl"
        scrollable
        centered
        content-class="p-10"
      >
        <div v-if="selectedQuestion">
          <h2 class="text-center pb-10" v-html="selectedQuestion.title" />
          <div class="d-flex align-items-center justify-content-between row">
            <div
              class="d-flex flex-column col-xl-4 answer ove"
              v-for="(answer, answerIndex) in selectedQuestion.choices"
              :key="answerIndex"
            >
              <b-button
                @click="doAnswer(answer.value, selectedQuestion, answerIndex)"
                :disabled="isAnswered"
                class="font-weight-bolder btn-shadow-hover p-8 font-size-lg"
                :class="
                  preset !== false && preset.index === answerIndex
                    ? ''
                    : answerIndex === answeredIndex
                    ? selectedQuestion.valuesRelation[answer.value] === 1
                      ? 'text-white'
                      : 'text-white'
                    : selectedQuestion.valuesRelation[answer.value] === 1
                    ? isAnswered
                      ? 'light-primary'
                      : 'light'
                    : 'light'
                "
                style="border-radius: 60px"
                :variant="
                  preset !== false && preset.index === answerIndex
                    ? 'secondary'
                    : answerIndex === answeredIndex
                    ? selectedQuestion.valuesRelation[answer.value] === 1
                      ? 'primary'
                      : 'danger'
                    : selectedQuestion.valuesRelation[answer.value] === 1
                    ? isAnswered
                      ? 'light-primary'
                      : 'light'
                    : 'light'
                "
                v-html="answer.text"
              />
            </div>
          </div>
          <div
            class="card card-custom mb-2 mt-8 p-4 cardround"
            id="answer"
            style="border-radius: 100px !important;"
            v-show="isAnswered"
          >
            <div class="text-dark-75 font-weight-boldest pl-15">
              <div
                class="d-flex flex-column"
                style="text-align: left; font-size: 1.2em; padding-right: 4.5em;"
              >
                <p v-html="message" />
              </div>
            </div>
            <div class="mt-4 text-center">
              <b-btn variant="secondary" @click="closeModal">
                CONTINUAR
              </b-btn>
            </div>
          </div>
        </div>
      </b-modal>
      <div id="escaped" v-if="markFinished">
        <h1>Escape!</h1>
        <div>
          <b-button
            variant="secondary"
            @click="finishCyberGaming(session.surveyEntityId)"
            class="btn font-weight-bolder text-uppercase btn-primary py-4 px-6"
          >
            <div class="d-flex align-items-center">
              <span style=" white-space: nowrap">
                {{ $t("generic.buttons.finish") }}
              </span>
            </div>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style type="text/css">
.st0 {
  fill: #e6e6e6;
}
.st1 {
  fill: #e6e6e6;
  stroke: #000000;
  stroke-width: 0.2578;
  stroke-miterlimit: 10;
}
.st2 {
  fill: #e6e6e6;
  fill-opacity: 0;
}
.st3 {
  opacity: 0;
}
</style>

<script>
import _ from "lodash";

import { getUserInteraction, saveInteraction } from "@/api/user/traits.api";
import store from "@/store";
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "Cybertest",
  components: {
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      session: {},
      locks: {},
      actualIndex: 0,
      isAnswered: false,
      selectedQuestion: false,
      message: "",
      answeredIndex: false,
      markFinished: false,
      initialElementsDone: 0,
      loading: false,
      preset: false,
      colors: [
        "turquoiseBlueColor",
        "greenishColor",
        "pinkColor",
        "greyishBlueColor",
        "darkMagentaColor",
        "brightBlueColor",
        "palestBlueColor",
        "magentaColor"
      ],
      answered: [],
      srcSVG: false,
      loaded: false
    };
  },
  mounted() {
    getUserInteraction(this.$route.params.surveyEntityId, true, true).then(
      response => {
        this.session = response.data.records;
        this.initialElementsDone = this.session.totalelementsdone;

        if (this.$route.query.uri) {
          this.srcSVG = this.$route.query.uri;
        } else {
          this.srcSVG =
            "https://dkdzifbe1uos6.cloudfront.net/gaming_svg/" +
            this.session.surveyTypeId +
            ".svg";
        }
      }
    );
  },
  watch: {
    session(value) {
      if (value.questions.length > 0) {
        _.each(this.session.questions, (question, index) => {
          if (this.loaded) {
            // REPAINT ALL OF THEM
            let color = this.colorResponse(question.questionDone);
            this.paintPadlock(question.name, color);
          }

          let overFunction = this.mouseOverElement(question, index);
          document.addEventListener("mouseover", overFunction, true);

          let outFunction = this.mouseOutElement(question, index);
          document.addEventListener("mouseout", outFunction, true);

          let clickFunction = this.clickElement(question);
          document.addEventListener("click", clickFunction, true);
        });
      }
    }
  },
  methods: {
    mouseOverElement(question, index) {
      return e => {
        if (e.target.id == question.name + "_btn") {
          if (!this.locks[question.name] && question.questionDone == -1) {
            document
              .getElementById(question.name)
              .classList.add(this.colors[index]);
          }
        }
      };
    },
    mouseOutElement(question, index) {
      return e => {
        if (e.target.id == question.name + "_btn") {
          if (!this.locks[question.name] && question.questionDone == -1)
            document
              .getElementById(question.name)
              .classList.remove(this.colors[index]);
        }
      };
    },
    clickElement(question) {
      return e => {
        if (e.target.id == question.name + "_btn") {
          if (!this.locks[question.name] && question.questionDone == -1)
            this.openQuestion(question);
        }
      };
    },
    svgLoaded() {
      let questions = _.map(this.session.questions, question => {
        return question.name;
      });
      let elements = document.querySelectorAll("[id^=SINGLE_]:not([id$=btn])");
      elements = _.map(Array.from(elements), element => {
        return element.id;
      });

      if (
        JSON.stringify(questions.sort()) === JSON.stringify(elements.sort())
      ) {
        _.each(this.session.questions, (question, index) => {
          let elementColor = this.colors[questions.length - (index + 1)];
          let color = this.colorResponse(question.questionDone);
          this.paintPadlock(question.name, color);
          if (question.questionDone !== -1)
            document.getElementById(question.name).classList.add(elementColor);
        });
      } else {
        this.$swal(
          "Error al cargar la survey",
          "Ha ocurrido un error al cargar la survey, porfavor contacte con el servicio de soporte",
          "error"
        );
        this.$router.push({ name: "Dashboard" });
      }

      this.loaded = true;
    },
    colorResponse(questionDone) {
      return questionDone == 1
        ? "correct"
        : questionDone == -1
        ? "white"
        : "incorrect";
    },
    openQuestion(question) {
      this.selectedQuestion = question;
      this.$bvModal.show("question-modal");
      this.locks[question.name] = true;
    },
    closeModal() {
      this.selectedQuestion = false;
      this.message = "";
      this.isAnswered = false;
      this.answeredIndex = false;
      this.$bvModal.hide("question-modal");
    },
    paintPadlock(name, color) {
      let padlock = document.getElementById("padlock_" + name);
      _.each(padlock.children, child => {
        if (color === "white") {
          if (_.includes(child.classList, "locked")) {
            child.classList.remove("opacity-1");
          } else if (_.includes(child.classList, "unlocked")) {
            child.classList.add("opacity-1");
          }
        } else {
          if (_.includes(child.classList, "unlocked")) {
            child.classList.remove("opacity-1");
          } else if (_.includes(child.classList, "locked")) {
            child.classList.add("opacity-1");
          }
        }

        // document.getElementById(name).classList.add(color);
        child.classList.add(color);
      });
    },
    finishCyberGaming(id) {
      setTimeout(() => {
        store.dispatch("Profile/reset");
        store.dispatch("User/reset");
        store.dispatch("Interactions/reset");
        store.dispatch("User/reset_bot");
        this.$router.push({
          name: "TrainingDetail",
          params: { id: id }
        });
      }, 500);
    },
    goToDash() {
      store.dispatch("Profile/reset");
      store.dispatch("User/reset");
      store.dispatch("Interactions/reset");
      store.dispatch("User/reset_bot");
      this.$router.push({
        name: "Dashboard"
      });
    },
    openDoor() {
      this.markFinished = true;

      if (document.getElementById("open-door")) {
        document.getElementById("open-door").classList.remove("opacity-0");
        document.getElementById("open-door").classList.remove("st3");
        document.getElementById("door").classList.remove("opacity-100");
      }
      if (
        document.getElementById("door") &&
        document.getElementById("open-door")
      ) {
        document.getElementById("door").classList.add("d-none");
        document.getElementById("open-door").classList.add("opacity-100");
        document.getElementById("open-door").classList.add("hover-btn");
      }
    },
    doAnswer(value, question, index) {
      this.loading = true;
      let answer = {};
      this.preset = false;
      answer[question.name] = value;
      let toSend = {
        entityId: this.session.entityId,
        entityType: this.session.entityType,
        stakeholderCompanyId: this.session.stakeholderCompanyId,
        surveyId: this.session.surveyId,
        surveyEntityId: this.session.surveyEntityId,
        surveyTypeId: this.session.surveyTypeId,
        templateSurveyId: this.session.templateSurveyId,
        totalelements: this.session.totalelements,
        totalelementsdone: this.session.totalelementsdone,
        stakeholderId: this.session.stakeholderId,
        questions: answer
      };
      this.session.totalelementsdone++;
      if (this.session.totalelements === this.session.totalelementsdone) {
        this.markFinished = true;
        this.openDoor();
      }
      this.isAnswered = true;
      this.answeredIndex = index;
      saveInteraction(this.$route.params.surveyEntityId, toSend, true).then(
        response => {
          this.message = _.find(response.data.records.questions, e => {
            return e.type == "info";
          })?.title;
          this.initialElementsDone = this.session.totalelementsdone;
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
.gaming-container {
  background-color: #051217;
  height: 90vh;
  #escaped {
    display: grid;
    position: absolute;
    z-index: 100;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--white);
    text-align: center;
    margin: 0;
    font-family: "Bangers", cursive;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden;

    h1 {
      font-size: 300px;
      justify-self: center;
      align-self: center;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    /* Room scene */
    .st0 {
      fill: var(--white);
      transition: 0.4s ease-in-out;
    }
    #solidfill {
      fill: var(--white);
    }
    #poster {
      transition: 0.4s ease-in-out;
      opacity: 1;
    }

    .white {
      &.unlocked {
        fill: #e6e6e6;
      }
      .st0 {
        fill: #e6e6e6;
      }
    }
    .greenishColor {
      &.unlocked {
        fill: #388d3c;
      }
      .st0 {
        fill: #388d3c;
      }
    }
    .greyishBlueColor {
      &.unlocked {
        fill: #147193;
      }
      .st0 {
        fill: #147193;
      }
    }
    .brightBlueColor {
      &.unlocked {
        fill: #0c4383;
      }
      .st0 {
        fill: #0c4383;
      }
    }
    .turquoiseBlueColor {
      &.unlocked {
        fill: #22a0b6;
      }
      .st0 {
        fill: #22a0b6;
      }
    }
    .palestBlueColor {
      &.unlocked {
        fill: #6a96b9;
      }
      .st0 {
        fill: #6a96b9;
      }
    }
    .darkMagentaColor {
      &.unlocked {
        fill: #7b1346;
      }
      .st0 {
        fill: #7b1346;
      }
    }
    .magentaColor {
      &.unlocked {
        fill: #cb0c59;
      }
      .st0 {
        fill: #cb0c59;
      }
    }
    .pinkColor {
      &.unlocked {
        fill: #eb649f;
      }
      .st0 {
        fill: #eb649f;
      }
    }

    .correct {
      &.unlocked {
        fill: rgb(0, 255, 0);
      }
      .st0 {
        fill: rgb(0, 255, 0);
      }
    }

    .incorrect {
      &.unlocked {
        fill: rgb(255, 0, 76);
      }
      .st0 {
        fill: rgb(255, 0, 76);
      }
    }

    .hover-btn:hover {
      cursor: pointer;
    }
  }
}
</style>
